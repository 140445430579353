import moment from "moment";

export const dateDiff = (startDate, endDate) => {
  const startDay = moment(startDate).format("DD.MM.YYYY");
  const endDay = moment(endDate).format("DD.MM.YYYY");
  if (startDay === endDay) {
    return `${startDay} ${moment(startDate).format("HH:mm:ss")} - ${moment(endDate).format(
      "HH:mm:ss"
    )}`;
  }

  return `${moment(startDate).format("DD.MM.YYYY HH:mm:ss")} - ${moment(endDate).format(
    "DD.MM.YYYY HH:mm:ss"
  )}`
};
