<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <h2>{{ $t('storeItemsList') }} {{ date }}</h2>
      <grid-view-table
        :id="'store-item-history'"
        :columns="columns"
        :path="'store-item-history'"
        :extra-params="extraParams"
      />
    </div>
  </div>
</template>

<script>
  import GridViewTable from '../../components/GridViewTable';
  import { APIService } from '../../services/api';
  import { columns } from './grid-views/store-history-items';
  import { dateDiff } from './plugins/dateDiff';

  export default {
    name: 'ViewStoreHistory',
    components: {
      GridViewTable,
    },
    props: {
      storeHistoryId: {
        type: String,
        required: true,
      },
    },
    data: () => {
      return {
        storeHistory: null,
        columns,
      }
    },
    computed: {
      date: function() {
        return this.storeHistory
          ? dateDiff(this.storeHistory.createdAt, this.storeHistory.updatedAt)
          : ''
      },
      extraParams: function () {
        return `&join=prices&join=store&join=barcode&join=product&join=tagItemPrice&join=tagItemPrice.tagItem&join=storeHistory&filter=storeHistory.id||eq||${this.storeHistoryId}`
      }
    },
    async beforeMount() {
      try {
        this.storeHistory = await APIService.get(`store-history/${this.storeHistoryId}`);
      } catch (error) {
        console.error('Store history record not found');
      }
    },
  }
</script>
